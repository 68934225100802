<template>
  <div class="jobs-manage">
      <div class="jobs-manage-header">
           <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
                type="text"
                v-model="search"
                placeholder="חיפוש"
                style="width:100%"
            />
            </span>
             <input style="margin-right:5px;" type="date" v-model="fromDate" />
             <input style="margin-right:5px;" type="date" v-model="toDate" />
             <el-button style="margin-right:5px;" type="primary" @click="handleClearDates">איפוס תאריכים</el-button>
      </div>
      <div class="jobs-manage-display-btns">
        <div class="btn news">
             <el-button @click="handle_records('new')" type="success">מודעות פעילות</el-button>
        </div>
        <div class="btn olds">
            <el-button @click="handle_records('old')" type="danger">מודעות לא פעילות</el-button>
        </div>
        <div class="btn all">
            <el-button @click="handle_records('all')" type="info">כל המודעות</el-button>
        </div>
      </div>
      <div class="jobs-manage-content">
          <template v-for="ad in filter_records_by_search" :key="ad.id">
            <div class="ad">
                <div class="ad-image">
                    <img :src="ad.image.url">  
                </div>
                <div class="ad-info ad-title">
                    <p>{{ad.job_header}}</p>
                </div>
                <div class="ad-info publish-date">
                    <p>פורסם בתאריך: {{ad.createdAt.toDate().toLocaleDateString('he')}}</p>
                </div>
                <div class="ad-info applicator-amount">
                    <p>מס פניות: {{ad.applicators.length}}</p>
                </div>
                <div class="ad-info status">
                    <p v-if="ad.status=='new'">
                        סטטוס: <span style="color:green">פעיל</span>
                    </p>
                    <p v-if="ad.status=='old'">
                        סטטוס: <span style="color:red">לא פעיל</span>
                    </p>
                </div>
                <div class="ad-info ad-actions">
                    <el-select v-model="choosed_status" placeholder="סטטוס">
                            <el-option
                                v-for="status in status_options"
                                :key="status.value"
                                :label="status.label"
                                :value="status.value"
                                >
                            </el-option>
                    </el-select>
                    <el-button @click="handle_update_status(ad)" style="margin-right:5px;" type="success">עדכן</el-button>
                    <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="ניהול מגישי משרה"
                        placement="top-start"
                    >
                        <div @click="handle_applicators(ad)" class="action-applicators">
                            <i class="material-icons">people</i>
                        </div>
                    </el-tooltip>
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="עריכת מודעה"
                        placement="top-start"
                    >
                        <div @click="handle_edit(ad)" class="action-edit">
                            <i class="material-icons">edit</i>
                        </div>
                    </el-tooltip>
                    
                    <div @click="handle_delete_ad(ad)" class="action-remove">
                        <i class="material-icons">delete</i>
                    </div>
                </div>
            </div>
          </template>
      </div>
  </div>
    <el-dialog
        v-model="show_applicators"
        top='5vh'
        width="70%"
    >
        <div class="applicators">
            <div class="applicators-header">
                <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                    type="text"
                    v-model="applicator_search"
                    placeholder="חיפוש"
                    style="width:100%"
                />
                </span>
                <div class="applicators-description">
                    <h1>{{choosed_ad.job_header}}</h1>
                    <p>{{choosed_ad.job_company}}</p>
                </div>
            </div>
            <div class="applicators-content">
                 <table id="records">
            <tr>
                <th>הגיש בתאריך</th>
                <th>שם המועמד</th>
                <th>דוא"ל</th>
                <th>מיומנויות שסומנו</th>
                <th>מיומנויות שלא סומנו</th>
                <th>טלפון</th>
                <th>קורות חיים</th>
            </tr>
            <h1  style="text-align:center;" v-if="choosed_ad.applicators.length==0">אין מועמדים</h1>
             <template v-for="(user,index) in filter_applicator_by_search" :key="index">
                 <tr>
                    <td>{{user.createdAt.toDate().toLocaleDateString('he')}}</td>
                    <td>{{user.first_name}} {{user.last_name}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.skills.join(',')}}</td>
                    <td>{{user.not_skills.join(',')}}</td>
                    <td>{{user.phone}}</td>
                    <td>
                         <a :href="user.cv.url" target="_blank">
                             <i style="color:crimson;" class="material-icons">picture_as_pdf</i>
                         </a>
                    </td>
                 </tr>
             </template>
        </table>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import InputText from "primevue/inputtext";
import { ref } from '@vue/reactivity';
import { computed, onMounted } from '@vue/runtime-core';
import {projectFirestore} from '../../../../firebase/config'
import {alert,alert_confirm,slide_pop_error} from '../../../../Methods/Msgs'
import use_jobs_storage from '../../../../Methods/use_jobs_storage'
export default {
    components:{InputText},
    emits:['edit'],
    setup(_,{emit}){
        const {deleteImage}  = use_jobs_storage()
        const choosed_ad = ref(null)
        const show_applicators = ref(false)
        const applicator_search = ref('')
        const isPending = ref(false)
        const status_options = ref([
            {
                value:'new',
                label:'פעיל'
            },
            {
                value:'old',
                label:'לא פעיל'
            }
        ])
        const search = ref('')
        const fromDate = ref('')
        const toDate = ref('')
        const records = ref([])
        const choosed_status = ref('')
        const filter_applicator_by_search = ref(computed(()=>{
            if(applicator_search.value=="")return choosed_ad.value.applicators.sort((a,b)=>{
                return new Date(a.createdAt.seconds * 1000) - new Date(b.createdAt.seconds * 1000)
            })
            return choosed_ad.value.applicators.filter(applicator=>{
                if(new Date(applicator.createdAt.seconds * 1000).toLocaleDateString('he').includes(applicator_search.value)) return applicator
                if(applicator.first_name.includes(applicator_search.value)) return applicator
                if(applicator.last_name.includes(applicator_search.value)) return applicator
                if(applicator.email.includes(applicator_search.value)) return applicator
                if(applicator.phone.includes(applicator_search.value)) return applicator
            })

        }))
        const filter_records_by_search =ref(computed(()=>{
             if(search.value == '') {
                 if(fromDate.value && toDate.value){
                    return records.value.filter(record=>{
                        if(stringTodate(fromDate.value)<=timestampToDate(record.createdAt) &&
                        stringTodate(toDate.value)>=timestampToDate(record.createdAt)){
                            return record
                        }
                    })
                 }
                 return records.value;
             }
             else{
                let results = records.value.filter(record=>{
                    if(new Date(record.createdAt.seconds * 1000).toLocaleDateString('he').includes(search.value)) return record
                    if (record.job_header.includes(search.value)) return record;
                    if (record.applicators.length==search.value) return record;
                })
                if(fromDate.value && toDate.value){
                    return results.filter(record=>{
                        if(stringTodate(fromDate.value)<=timestampToDate(record.createdAt) &&
                        stringTodate(toDate.value)>=timestampToDate(record.createdAt)){
                            return record
                        }
                    })
                }else{
                    return results
                }
             }
        }))

        const handle_applicators = (ad)=>{
            choosed_ad.value = ad
            show_applicators.value = !show_applicators.value
        }   
        const handleClearDates = ()=>{
            fromDate.value=''
            toDate.value=''
        }

        const handle_records = async(status)=>{
            isPending.value = true
            if(status!='all'){
                const docs = await projectFirestore.collection('Jobs').where('status','==',status).orderBy('createdAt','desc').get()
                records.value = docs.docs.map(doc=>doc.data())
            }else{
               const docs = await projectFirestore.collection('Jobs').orderBy('createdAt','desc').get()
               records.value = docs.docs.map(doc=>doc.data()) 
            }
            
            
            isPending.value = false
        }

        const handle_edit = (ad) =>{
            emit('edit',ad.id)
        }

        const handle_delete_ad = (ad)=>{
            alert_confirm('האם אתה בטוח שברצונך למחוק מודעה זו?')
            .then(async res=>{
                if(res.isConfirmed){
                    await deleteImage(ad.image.file_path)
                    await delete_ad_from_db(ad.id)
                    const index = records.value.findIndex(record=>record.id==ad.id)
                    if(index!=-1){
                        records.value.splice(index,1)
                    }
                }
            })
        }

        const delete_ad_from_db=async(id)=>{
            await projectFirestore.collection('Jobs').doc(id).delete()
        }

         // for dates
        const stringTodate=(dateString)=>{
            const d=new Date(dateString)
            d.setHours(0,0,0,0)
            return d
        }
        const timestampToDate=(date)=>{
            const d = new Date(date.seconds * 1000)
            d.setHours(0,0,0,0)
            return d
        }

        const handle_update_status = async(ad)=>{
            if(ad.status == choosed_status.value){
                slide_pop_error('אינך יכול לעדכן לאותו הסטטוס')
                choosed_status.value = ''
            }
            else if(choosed_status.value==''){
                slide_pop_error('עליך לבחור סטטוס על מנת לעדכן')
            }
            else{
                await projectFirestore.collection('Jobs').doc(ad.id)
                .set({
                    status:choosed_status.value
                },{merge:true})
               alert('success','הצלחה','סטטוס שונה בהצלחה')
                .then(()=>{
                    const index = records.value.findIndex(record=>record.id==ad.id)
                    if(index!=-1){
                        records.value.splice(index,1)
                    }
                })
            }
        }
        onMounted(async()=>{
            await handle_records('new')
        })
        
        return{
            handle_records,
            handle_update_status,
            handleClearDates,
            handle_delete_ad,
            handle_edit,
            handle_applicators,
            choosed_ad,
            show_applicators,
            filter_records_by_search,
            choosed_status,
            records,
            isPending,
            search,
            fromDate,
            toDate,
            status_options,
            applicator_search,
            filter_applicator_by_search
        }
    }
}
</script>

<style scoped>
    .jobs-manage{
        width: 100%;
        height: 100%;
    }
    .jobs-manage-header{
        padding: 5px;
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center; 
    }
    input[type="date"] {
        height: 40px;
        border: 0.5px solid lightgrey;
        border-radius: 3px;
    }
    input[type="date"]:hover {
        border: 1.1px solid lightblue;
    }
    input[type="date"]:focus {
        outline: none;
        border: 1.1px solid lightblue;
    }
    .jobs-manage-display-btns{
        width: 100%;
        height: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .jobs-manage-display-btns .btn{
        margin-left: 5px;
    }
    .jobs-manage-content{
        padding: 10px;
        width: 100%;
        height: 85%;
        overflow:hidden;
        overflow-y: auto;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 5px;
    }
    .jobs-manage-content .ad{
        border-radius: 10px;
        height: 500px;
        background: var(--secondary);
    }
    .jobs-manage-content .ad .ad-image{
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .jobs-manage-content .ad .ad-image img{
        max-width: 90%;
        max-height: 100%;
    }
    .ad-info{
        width: 100%;
        height: calc(40% / 5);
        color: #fff;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;
    }
    .ad-actions{
         position: relative;
         flex-direction: row;
         justify-content: unset;
    }
    .action-applicators{
        position: absolute;
        bottom: 100px;
        left: 5px;
        width: 50px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: mediumblue;
        cursor: pointer;
    }
    .action-edit{
        position: absolute;
        bottom: 50px;
        left: 5px;
        width: 50px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: lightskyblue;
        cursor: pointer;
    }
    .action-remove{
        position: absolute;
        bottom: 0;
        left: 5px;
        width: 50px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: crimson;
        cursor: pointer;
    }
    .action-remove i{
        font-size: 30px;
    }
    .applicators{
        width: 100%;
        height: 700px;
        overflow:hidden;
    }
    .applicators-header{
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .applicators-content{
        width: 100%;
        height: 90%;
        overflow:hidden;
        overflow-y: auto;
    }
    .applicators-description{
        width: 60%;
        color: #333;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
    }
    #records {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }
    #records td, #records th {
        border: 1px solid #ddd;
        padding: 2px;
        text-align: center;
        width:100px;
    }
    #records tr:nth-child(even){
        background-color: #f2f2f2;
    }
    #records tr:hover {
        background-color: #ddd;
    }
    #records th {
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: #7367f0;
        color: white;
    }
    

</style>